import styles from "./Footer.module.scss";
import BlockContainer from "components/layout/BlockContainer/BlockContainer";
import { Icon } from "SiteComponents";
import { LabelValueProps } from "../../../base-props/LabelValueProps";
import { ReactComponentPropsBase } from "../../../base-props/ReactComponentPropsBase";
import IconMS from "../../utils/IconMS/IconMS";

import ImdiLogo from "components/base/ImdiLogo/ImdiLogo";
import { LinkProps } from "src/base-props/LinkProps";

interface SoMe extends LinkProps {
  type: string;
}

export interface FooterProps extends ReactComponentPropsBase {
  labels: {
    imdiLinkLabel: string;
    scrollToTopLabel: string;
  };
  contact?: {
    title?: string;
    orgNumber?: LabelValueProps;
    address?: {
      street?: string;
      city?: string;
    };
  };
  linkListCollection?: {
    title: string;
    linkList: LinkProps[];
  }[];
  soMe: SoMe[];
  linkToImdi?: LinkProps;
}

const Footer: React.FC<FooterProps> = ({
  labels,
  contact,
  linkListCollection,
  soMe,
  linkToImdi,
}) => {
  const { title, orgNumber, address } = contact || {};

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <footer className={styles.footer}>
      <BlockContainer className={styles.footerBlock}>
        <div className={styles.footerTop}>
          <a
            href={linkToImdi?.url ?? "https://imdi.no"}
            aria-label={labels.imdiLinkLabel}
            className={styles.logo}
          >
            <ImdiLogo />
          </a>
          <button
            className={styles.scrollUpButton}
            aria-label={labels.scrollToTopLabel}
            onClick={scrollToTop}
          >
            <IconMS name="keyboard_arrow_up" />
          </button>
        </div>
        <div className={styles.content}>
          <div className={styles.contact}>
            {title && <h2>{title}</h2>}
            <div className={styles.contactInfo}>
              {address && (
                <div>
                  <div>{address.street}</div>
                  <div>{address.city}</div>
                </div>
              )}
              {orgNumber && (
                <p>
                  {orgNumber.label} {orgNumber.value}
                </p>
              )}
            </div>
            {soMe && (
              <div className={styles.SoMe}>
                {soMe.map(
                  ({ type, url }) =>
                    url && (
                      <a
                        key={url}
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={styles.link}
                        aria-label={type}
                      >
                        <Icon name={type} className={styles.SoMeIcon} />
                      </a>
                    )
                )}
              </div>
            )}
          </div>
          {linkListCollection && (
            <>
              {linkListCollection.map(
                ({ linkList, title }) =>
                  linkList &&
                  linkList.length > 0 && (
                    <div className={styles.linkListContainer} key={title}>
                      {title && <h2>{title}</h2>}
                      <ul className={styles.linkList}>
                        {linkList &&
                          linkList.map(link => (
                            <li key={link.text}>
                              <a
                                href={link.url}
                                target={link.target}
                                className={styles.link}
                              >
                                {link.text}
                                {link.externalLinkDomain ? (
                                  <IconMS name="open_in_new" size="15px" />
                                ) : null}
                              </a>
                            </li>
                          ))}
                      </ul>
                    </div>
                  )
              )}
            </>
          )}
        </div>
      </BlockContainer>
    </footer>
  );
};

export default Footer;
